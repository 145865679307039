<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>
          <span class="rollback" @click="rollback"
            ><i class="fas fa-arrow-circle-left"></i
          ></span>
          {{ $t("document.title") }}
          <span v-if="this.type == 'in'">
            ({{ $t("document.document in") }})
          </span>
          <span v-else> ({{ $t("document.document out") }})</span>
        </h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <DatePicker
          style="width: 100% !important;"
          type="year"
          v-model="year"
          @change="eventFilterYear($event)"
          placeholder="Select year"
        >
        </DatePicker>
      </div>
      <div class="btn-new-create-action">
        <div class="btn-document">
          <v-btn class="btn-save-change" style="margin-top: 2px !important;" @click="onCreate()"
            ><span><i class="fal fa-plus" style="margin-right: 5px;"></i></span> {{ $t("document.new_document") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listDocument.length > 0">
          <thead>
            <tr>
              <th class="text-left">{{ $t("document.no") }}</th>
              <th class="text-left">{{ $t("document.document number") }}</th>
              <th class="text-left">{{ $t("document.type") }}</th>
              <th class="text-left">{{ $t("document.year") }}</th>
              <th class="text-left">{{ $t("document.file") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listDocument" :key="idx">
              <td>{{ pagination.current_page * 10 - 10 + idx + 1 }}</td>
              <td>
                {{ item.document_number }}
              </td>
              <td>
                {{ item.type }}
              </td>
              <td>
                {{ item.year }}
              </td>
              <td>
                <v-icon left @click="openFile(item.url_file)">
                  mdi-file-eye
                </v-icon>
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                        >Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination
          class="mt-5 mb-5"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="eventFilterYear"
        >
        </Pagination>
        <Loading v-if="isLoading" />
        <ModalEdit fullscreen>
          <template v-slot="{ close }">
            <Edit
              :documentItem="documentItem"
              @close="close"
              @success="eventFilterYear"
            />
          </template>
        </ModalEdit>
        <ModalDelete>
          <template v-slot="{ close }">
            <Delete
              :document_id="document_id"
              @close="close"
              @success="eventFilterYear"
            />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import Edit from "@/views/MainCompany/Document/Edit";
import Delete from "@/views/MainCompany/Document/Delete";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    DatePicker,
    Edit,
    Delete,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      modalEditEducation: false,
      isLoading: true,
      listDocument: [],
      documentItem: {},
      document_id: "",
      type: this.$route.query.type,
      year: new Date(),
    };
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank", "noreferrer");
    },
    onCreate() {
      this.$router
        .push({
          name: "document.create",
          query: {
            type: this.type,
          },
        })
        .catch(() => {});
    },
    eventFilterYear(event) {
      this.fetchDocument(this.year, this.type);
    },
    rollback() {
      this.$router.back();
    },
    filterDocument(document_id) {
      return (
        this.listDocument.filter((item) => {
          return item.id == document_id;
        })[0] || {}
      );
    },

    onEdit(document_id) {
      this.documentItem = {
        ...this.filterDocument(document_id),
      };
      this.$store.commit("modalEdit_State", true);
    },
    onDeleted(document_id) {
      this.document_id = document_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchDocument(year, type) {
      const years = moment(year).format("YYYY");
      this.isLoading = true;
      this.$axios
        .get(`company/list/documents/${type}`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            year: years || null,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listDocument = res.data.data;
            console.log(this.listDocument);
            this.pagination = res.data.pagination;
            if (!this.listDocument.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchDocument(this.year, this.type);
  },
};
</script>

<style scoped lang="scss"></style>
